import React from 'react';

import TextContent from 'components/shared/text-content';
import MainLayout from 'layouts/main';

const DataProtection = () => (
  <MainLayout language="de" menuItems={[{ label: { de: 'Home' }, path: '/de/' }]}>
    <TextContent>
      <h2 id="grundlegendes">Grundlegendes</h2>
      <p>
        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den
        Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber
        LIMEBIRD GmbH informieren.
      </p>
      <p>
        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen
        Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien
        und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung
        vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen
        Abständen wieder durchzulesen.
      </p>
      <p>
        Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”)
        finden Sie in Art. 4 DSGVO.
      </p>
      <h2 id="zugriffsdaten">Zugriffsdaten</h2>
      <p>
        Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten
        Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und
        speichern diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden
        so protokolliert:
      </p>
      <ul>
        <li>Besuchte Website</li>
        <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
        <li>Menge der gesendeten Daten in Byte</li>
        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Verwendete IP-Adresse</li>
      </ul>
      <p>
        Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die
        Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu
        können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung
        ausgenommen bis der Vorfall endgültig geklärt ist.
      </p>
      <h2 id="reichweitenmessungcookies">Reichweitenmessung &amp; Cookies</h2>
      <p>
        Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von
        unserem Server oder dem Server Dritter an den Browser des Nutzers übertragen werden. Bei
        Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr
        Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die
        Benutzerfreundlichkeit und Sicherheit dieser Website. Falls Sie nicht möchten, dass Cookies
        zur Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser
        Dateien hier widersprechen:
      </p>
      <ul>
        <li>
          Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:{' '}
          <a href="http://optout.networkadvertising.org/?c=1#!/">
            http://optout.networkadvertising.org/?c=1#!/
          </a>
        </li>
        <li>
          Cookie-Deaktivierungsseite der US-amerikanischen Website:{' '}
          <a href="http://optout.aboutads.info/?c=2#!/">http://optout.aboutads.info/?c=2#!/</a>
        </li>
        <li>
          Cookie-Deaktivierungsseite der europäischen Website:{' '}
          <a href="http://optout.networkadvertising.org/?c=1#!/">
            http://optout.networkadvertising.org/?c=1#!/
          </a>
        </li>
      </ul>
      <h2 id="erfassungundverarbeitungpersonenbezogenerdaten">
        Erfassung und Verarbeitung personenbezogener Daten
      </h2>
      <p>
        Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter,
        wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen. Als
        personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu
        bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name,
        Ihre E-Mail-Adresse und Telefonnummer.
      </p>
      <p>
        Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur
        Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne Personenbezug) Ihre
        Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten gehören z. B. die von Ihnen
        angeforderte Datei oder der Name Ihres Internet-Providers. Durch die Anonymisierung der
        Daten sind Rückschlüsse auf Ihre Person nicht möglich.
      </p>
      <h2 id="umgangmitkontaktdaten">Umgang mit Kontaktdaten</h2>
      <p>
        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten
        Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und
        Beantwortung Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese
        Daten nicht an Dritte weitergegeben.
      </p>
      <h2 id="googleanalytics">Google Analytics</h2>
      <p>
        Diese Website nutzt aufgrund unserer berechtigten Interessen zur Optimierung und Analyse
        unseres Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google
        Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043,
        USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien, welche
        auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen
        werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.
      </p>
      <p>
        Google LLC hält das europäische Datenschutzrecht ein und ist unter dem Privacy-Shield
        Abkommen zertifiziert:
        <br />
        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
        </a>
      </p>
      <p>
        Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb
        der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum und in den anderen
        Vertragsstaaten des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse zunächst
        ungekürzt in die USA an einen Server von Google übertragen und dort gekürzt. Durch diese
        Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte IP-Adresse
        des Nutzers wird nicht mit anderen von Google gespeicherten Daten kombiniert.
      </p>
      <p>
        Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als Websitebetreiber
        mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen
        eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der
        Internetnutzung verbundene Dienstleistungen.
      </p>
      <p>
        Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die Nutzung unseres
        Online-Angebots durch die einzelnen Nutzer auswerten zu können, z. B. um Reports über die
        Aktivität auf der Website zu erstellen, um unser Online-Angebot zu verbessern.
      </p>
      <p>
        Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu verhindern, indem
        Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass
        Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr
        Browser keine Cookies zulässt.
      </p>
      <p>
        Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten
        Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google
        Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
      </p>
      <p>Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:</p>
      <ul>
        <li>
          <a href="https://policies.google.com/privacy/partners?hl=de">
            https://policies.google.com/privacy/partners?hl=de
          </a>{' '}
          (Daten, die von GooglePartnern erhoben werden)
        </li>
        <li>
          <a href="https://adssettings.google.de/authenticated">
            https://adssettings.google.de/authenticated
          </a>{' '}
          (Einstellungen über Werbung, die Ihnen angezeigt wird)
        </li>
        <li>
          <a href="https://policies.google.com/technologies/ads?hl=de">
            https://policies.google.com/technologies/ads?hl=de
          </a>{' '}
          (Verwendung von Cookies in Anzeigen)
        </li>
      </ul>
      <h2 id="newsletterabonnement">Newsletter-Abonnement</h2>
      <p>
        Der Websitebetreiber bietet Ihnen einen Newsletter an, in welchem er Sie über aktuelle
        Geschehnisse und Angebote informiert. Möchten Sie den Newsletter abonnieren, müssen Sie eine
        valide E-Mail-Adresse angeben. Wenn Sie den Newsletter abonnieren, erklären Sie sich mit dem
        Newsletter-Empfang und den erläuterten Verfahren einverstanden.
      </p>
      <p>
        Der Newsletterversand erfolgt durch Mailchimp, der Newsletterversandplattform von Rocket
        Science Group LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA.
      </p>
      <p>
        Die E-Mail-Adressen unserer Newsletterempfänger, als auch deren weitere, im Rahmen dieser
        Hinweise beschriebenen Daten, werden auf den Servern von MailChimp in den USA gespeichert.
        MailChimp verwendet diese Informationen zum Versand und zur Auswertung der Newsletter in
        unserem Auftrag. Des Weiteren kann MailChimp nach eigenen Informationen diese Daten zur
        Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung
        des Versandes und der Darstellung der Newsletter oder für wirtschaftliche Zwecke, um zu
        bestimmen aus welchen Ländern die Empfänger kommen. MailChimp nutzt die Daten unserer
        Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder an Dritte
        weiterzugeben.
      </p>
      <p>
        Die Datenschutzbestimmungen von MailChimp finden Sie hier:{' '}
        <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>
      </p>
      <p>
        Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine pixelgroße Datei, die beim
        Öffnen des Newsletters von dem Server von MailChimp abgerufen wird. Im Rahmen dieses Abrufs
        werden zunächst technische Informationen, wie Informationen zum Browser und Ihrem System,
        als auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben. Diese Informationen werden zur
        technischen Verbesserung der Services anhand der technischen Daten oder der Zielgruppen und
        ihres Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind)
        oder der Zugriffszeiten genutzt.
      </p>
      <p>
        Zu den statistischen Erhebungen gehört ebenfalls die Feststellung, ob die Newsletter
        geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese
        Informationen können aus technischen Gründen zwar den einzelnen Newsletterempfängern
        zugeordnet werden. Es ist jedoch weder unser Bestreben, noch das von MailChimp, einzelne
        Nutzer zu beobachten. Die Auswertungen dienen uns viel mehr dazu, die Lesegewohnheiten
        unserer Nutzer zu erkennen und unsere Inhalte auf sie anzupassen oder unterschiedliche
        Inhalte entsprechend den Interessen unserer Nutzer zu versenden.
      </p>
      <p>
        Widerruf und Kündigung: Ihre Einwilligung zum Erhalt des Newsletter können Sie jederzeit
        widerrufen und somit das Newsletter-Abonnement kündigen. Nach Ihrer Kündigung erfolgt die
        Löschung Ihre personenbezogenen Daten. Ihre Einwilligung in den Newsletterversand erlischt
        gleichzeitig. Am Ende jedes Newsletters finden Sie den Link zur Kündigung.
      </p>
      <h2 id="googlewebfonts">Google Web Fonts</h2>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die
        von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten
        Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
        aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere
        Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer
        einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
        <a href="https://developers.google.com/fonts/faq">
          https://developers.google.com/fonts/faq
        </a>{' '}
        und in der Datenschutzerklärung von Google:{' '}
        <a href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
        .
      </p>
      <h2 id="rechtedesnutzers">Rechte des Nutzers</h2>
      <p>
        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten,
        welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf
        Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer
        personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität
        geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden,
        können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.
      </p>
      <h2 id="lschungvondaten">Löschung von Daten</h2>
      <p>
        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B.
        Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns
        gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und
        es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht
        durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind,
        erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt
        und nicht für andere Zwecke verarbeitet.
      </p>
      <h2 id="widerspruchsrecht">Widerspruchsrecht</h2>
      <p>
        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der
        Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen. Muster von
        datenschutz.org Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu
        Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung,
        Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte
        Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse:{' '}
        <a href="mailto://datenschutz@limebird.io">datenschutz@limebird.io</a>
      </p>
    </TextContent>
  </MainLayout>
);

export default DataProtection;
