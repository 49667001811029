import classNames from 'classnames/bind';
import React from 'react';

import styles from './text-content.module.scss';

const cx = classNames.bind(styles);

const TextContent = ({ children }) => (
  <section className={cx('wrapper')}>
    <div className={cx('container')}>
      <div className="container">{children}</div>
    </div>
  </section>
);

export default TextContent;
